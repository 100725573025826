import { AppProps } from 'next/app'
import { useRouter } from 'next/router'
import React, { useEffect } from 'react'
import { wrapper } from '../api/store'
import { Provider } from 'react-redux'
import * as gtag from '../libs/utils/ga'
import '../static/styles/globals.scss'

/**
 * MyApp
 * @param {any} Component
 * @param {any} pageProps
 * @return {ReactElement}
 */
export default function JOTA_UNICO({ Component, pageProps }: AppProps) {
    const router = useRouter()
    const { store } = wrapper.useWrappedStore(pageProps)

    useEffect(() => {
        if (
            typeof window !== 'undefined' &&
            typeof window.WebFont !== 'undefined'
        ) {
            window.WebFont.load({
                custom: {
                    families: ['Roboto:300,400,500,700'],
                    urls: ['/static/styles/fonts.css']
                }
            })
        }

        const handleRouteChange = (url: string) => {
            if (process.env.NODE_ENV === 'production') {
                gtag.pageview(url)
            }
        }
        router.events.on('routeChangeComplete', handleRouteChange)
        return () => {
            router.events.off('routeChangeComplete', handleRouteChange)
        }
    }, [router])

    return (
        <Provider store={store}>
            <Component {...pageProps} />
        </Provider>
    )
}
